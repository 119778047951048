(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("survey-core"), require("jspdf"));
	else if(typeof define === 'function' && define.amd)
		define("SurveyPDF", ["survey-core", "jspdf"], factory);
	else if(typeof exports === 'object')
		exports["SurveyPDF"] = factory(require("survey-core"), require("jspdf"));
	else
		root["SurveyPDF"] = factory(root["Survey"], root["jspdf"]);
})(self, (__WEBPACK_EXTERNAL_MODULE_survey_core__, __WEBPACK_EXTERNAL_MODULE_jspdf__) => {
return 